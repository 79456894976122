import queryString from 'query-string';

const Sales = {
  async loadAllCustomers(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/customer?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading customers information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async searchCustomer(baseApiUrl, searchFields, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        search: true,
      };

      for(let i = 0; i < Object.keys(searchFields).length; i++){
        const key = Object.keys(searchFields)[i];
        const value = searchFields[key];
        queryList[key] = value;
      };

      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/customer?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading customer information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  /* Load Customer by Id. */
  async loadCustomer(baseApiUrl, customerId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/customer/${customerId}?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading customer information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async createCustomer(baseApiUrl, customerInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const postValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        customerInfo: customerInfo,
      };

      const apiUrl = `${baseApiUrl}/customer`;
      const fetchResult = await fetch(apiUrl, {
        method: 'POST',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(postValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot create customer information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async updateCustomer(baseApiUrl, customerId, customerInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const putValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        customerInfo: customerInfo,
      };

      const apiUrl = `${baseApiUrl}/customer/${customerId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(putValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update customer information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async deleteCustomer(baseApiUrl, employeeId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const deleteValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      };

      const apiUrl = `${baseApiUrl}/customer/${employeeId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(deleteValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot delete customer information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async loadAllOrders(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/order?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading customers information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async searchOrder(baseApiUrl, searchFields, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        search: true,
      };

      for(let i = 0; i < Object.keys(searchFields).length; i++){
        const key = Object.keys(searchFields)[i];
        const value = searchFields[key];
        queryList[key] = value;
      };

      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/order?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading order information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  /* Load Order by Id. */
  async loadOrder(baseApiUrl, orderId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/order/${orderId}?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading order information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async createOrder(baseApiUrl, orderInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const postValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        orderInfo: orderInfo,
      };

      const apiUrl = `${baseApiUrl}/order`;
      const fetchResult = await fetch(apiUrl, {
        method: 'POST',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(postValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot create order information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async updateOrder(baseApiUrl, orderId, orderInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const putValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        orderInfo: orderInfo,
      };

      const apiUrl = `${baseApiUrl}/order/${orderId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(putValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update order information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async deleteOrder(baseApiUrl, orderId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const deleteValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      };

      const apiUrl = `${baseApiUrl}/order/${orderId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(deleteValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot delete order information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async loadFacebookPages(baseApiUrl, accessToken, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryFields = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      };
      if(accessToken){
        queryFields['facebook_access_token'] = accessToken;
      }
      const query = queryString.stringify(queryFields);
      const apiUrl = `${baseApiUrl}/sns/pages?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading pages information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async loadFacebookPage(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryFields = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      };
      const query = queryString.stringify(queryFields);
      const apiUrl = `${baseApiUrl}/sns/posts?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading pages information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async createFacebookPost(baseApiUrl, postContent, loginInfo){
    return new Promise(async(resolve, reject) => {
      const postValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        postContent: postContent,
      };

      const apiUrl = `${baseApiUrl}/sns/post`;
      const fetchResult = await fetch(apiUrl, {
        method: 'POST',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(postValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot create facebook post information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
};

export default Sales;
